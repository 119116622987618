import React from 'react';
import { css } from '@emotion/core';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import { IoLogoFacebook, IoLogoTwitter, IoLogoLinkedin } from 'react-icons/io';

const styles = {
  root: css`
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `,
  button: css`
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;

    &:hover,
    :focus {
      opacity: 0.8;
    }
  `,
  facebook: css`
    background-color: #3b5998;
  `,
  twitter: css`
    background-color: #1da1f2;
  `,
  linkedin: css`
    background-color: #0077b5;
  `,
};

interface Props {
  url: string;
  title: string;
}

const SocialShare: React.FC<Props> = ({ url, title }) => (
  <div css={styles.root}>
    <FacebookShareButton url={url} css={[styles.button, styles.facebook]}>
      <IoLogoFacebook size={20} />
    </FacebookShareButton>
    <TwitterShareButton
      url={url}
      title={title}
      via="cara_by_hidoc"
      css={[styles.button, styles.twitter]}
    >
      <IoLogoTwitter size={20} />
    </TwitterShareButton>
    <LinkedinShareButton
      url={url}
      title={title}
      css={[styles.button, styles.linkedin]}
    >
      <IoLogoLinkedin size={20} />
    </LinkedinShareButton>
  </div>
);

export default SocialShare;
