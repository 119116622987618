import React, { useState, useCallback, useMemo, useRef } from 'react';
import { css } from '@emotion/core';
import useOnClickOutside from 'use-onclickoutside';
import { useTransition, animated } from 'react-spring';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { DropdownProvider, useDropdownContext } from './DropdownContext';
import getZindex from '../../styles/zIndex';

interface Props {
  initialOpen?: boolean;
  title?: React.ReactNode;
  position?: 'left' | 'right';
}

const styles = {
  dropdown: css`
    position: relative;
  `,
  dropdownButton: css`
    color: inherit;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    outline: none;
    user-select: none;
    cursor: pointer;
    -moz-appearance: none;
    text-decoration: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  `,
  dropdownMenu: css`
    position: absolute;
    top: 100%;
    left: 0;
    right: auto;
    min-width: 10rem;
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    z-index: ${getZindex('dropdown')};
  `,
  left: css`
    left: auto;
    right: 0;
  `,
  dropdownItem: css`
    padding: 0.5rem 1rem;
    font-size: 14px;
    line-height: 17px;
    background-color: transparent;
    text-align: left;
    border: 0;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  `,
  dropdownItemActive: css`
    background-color: #f7f7f7;
  `,
};

interface EnhancedFunctionComponent<P = {}> extends React.FC<P> {
  Button: React.FC;
  Menu: React.FC<MenuProps>;
  Item: React.FC<ItemProps>;
}

const Dropdown: EnhancedFunctionComponent<Props> & {
  handleClickOutside?: () => void;
} = ({ initialOpen, title, children, ...rest }) => {
  const ref = useRef(null);
  const [open, setIsOpen] = useState(initialOpen || false);
  const toggle = useCallback(() => setIsOpen((prevOpen) => !prevOpen), []);
  const close = useCallback(() => setIsOpen(false), []);
  const value = useMemo(() => ({ open, toggle, setIsOpen }), [open, toggle]);

  useOnClickOutside(ref, close);

  return (
    <DropdownProvider value={value}>
      <div ref={ref} css={styles.dropdown} {...rest}>
        {title && <Button>{title}</Button>}
        {children}
      </div>
    </DropdownProvider>
  );
};

export const Button: React.FC = ({ children, ...rest }) => {
  const { open, toggle } = useDropdownContext();
  return (
    <button css={styles.dropdownButton} onClick={toggle} {...rest}>
      <span>{children}</span>
      {open ? (
        <FiChevronUp size={24} color="#a8a8a8" />
      ) : (
        <FiChevronDown size={24} color="#a8a8a8" />
      )}
    </button>
  );
};

interface MenuProps {
  right?: boolean;
}

export const Menu: React.FC<MenuProps> = ({ right, ...rest }) => {
  const { open } = useDropdownContext();
  const transitions = useTransition(open, null, {
    from: { opacity: 0, transform: 'translateY(10px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(10px)' },
  });
  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              css={[styles.dropdownMenu, right && styles.left]}
              {...rest}
              style={props}
            />
          )
      )}
    </>
  );
};

interface ItemProps {
  active?: boolean;
  onClick?: () => void;
}

export const Item: React.FC<ItemProps> = ({ onClick, active, ...rest }) => {
  const { setIsOpen } = useDropdownContext();
  const onClickHandle = () => {
    setIsOpen(false);
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      css={[styles.dropdownItem, active && styles.dropdownItemActive]}
      onClick={onClickHandle}
      {...rest}
    />
  );
};

Dropdown.Button = Button;
Dropdown.Menu = Menu;
Dropdown.Item = Item;

export default Dropdown;
