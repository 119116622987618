import { createContext, useContext } from 'react';

const DropdownContext = createContext({
  open: false,
  toggle: () => {}, // eslint-disable-line
  setIsOpen: (isOpen: boolean) => {}, // eslint-disable-line
});

const DropdownProvider = DropdownContext.Provider;
const DropdownConsumer = DropdownContext.Consumer;

function useDropdownContext() {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error(
      "Dropdown compound components cant't be rendered outside Dropdown component"
    );
  }
  return context;
}

export {
  DropdownContext,
  DropdownProvider,
  DropdownConsumer,
  useDropdownContext,
};
