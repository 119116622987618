import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore https://github.com/leozdgao/react-async-script-loader/pull/38
import scriptLoader from 'react-async-script-loader';

interface ScriptLoaderProps {
  isScriptLoaded: boolean;
  isScriptLoadSucceed: boolean;
  onScriptLoaded: () => void;
}

const WorkableJobs: React.FC<ScriptLoaderProps> = ({
  isScriptLoaded,
  isScriptLoadSucceed,
}) => {
  if (isScriptLoaded && isScriptLoadSucceed) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    whr(document).ready(function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      whr_embed(391761, {
        detail: 'titles',
        base: 'jobs',
        zoom: 'country',
        grouping: 'none',
      });
    });
  }
  return <div id="whr_embed_hook" css={{ flex: 1, alignItems: 'center' }} />;
};

export default scriptLoader(['https://www.workable.com/assets/embed.js'])(
  WorkableJobs
);
