import React from 'react';
import SlickSlider, { Settings } from 'react-slick';
import { v4 } from 'uuid';

interface OwnProps {
  forwardedRef: React.Ref<SlickSlider>;
}

type Props = OwnProps & Settings;

interface State {
  isClient: boolean;
}

class Slider extends React.PureComponent<Props, State> {
  state = {
    isClient: false,
  };

  componentDidMount() {
    this.setState({ isClient: true });
  }

  render() {
    const { children, responsive, forwardedRef, ...rest } = this.props;
    const { isClient } = this.state;

    return (
      <SlickSlider
        ref={forwardedRef}
        key={isClient ? 'client' + v4() : 'server' + v4()}
        responsive={isClient ? responsive : null}
        {...rest}
      >
        {children}
      </SlickSlider>
    );
  }
}

const SliderWithRef = React.forwardRef(
  (
    props: { children: React.ReactNode } & Settings,
    ref: React.Ref<SlickSlider>
  ) => <Slider {...props} forwardedRef={ref} />
);

export default SliderWithRef;
