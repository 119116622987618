import styled from '@emotion/styled';
import { Theme } from '../styles/theme';

interface Props {
  color?: keyof Theme['colors'];
  textAlign?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
}

const Heading = styled.h2<Props>`
  margin-top: 0;
  color: ${({ color, theme }) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore https://github.com/emotion-js/emotion/issues/802
    color ? theme.colors[color] : theme.colors.text};
  font-size: 30px;
  line-height: 36px;
  text-align: ${({ textAlign }) => textAlign && textAlign};
`;

Heading.displayName = 'Heading';

export default Heading;
