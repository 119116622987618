import React from 'react';

interface Props {
  fallback?: React.ReactNode;
}

interface State {
  shouldRender: boolean;
}

class NoSSR extends React.Component<Props, State> {
  state = {
    shouldRender: false,
  };

  componentDidMount() {
    this.setState({ shouldRender: true });
  }

  render() {
    const { children, fallback } = this.props;
    const { shouldRender } = this.state;

    return shouldRender ? children : fallback;
  }
}

export default NoSSR;
